:root {
  --main-color: #e94f37;
  --second-color: #4bbea7;
  --third-color: #f6f7eb;
  --fourth-color: #132b3f;
  --fifth-color: #131516;

  --large-font: calc(30px + 6 * ((100vw - 320px) / 680));
  --medium-font: calc(10px + 12 * ((100vw - 320px) / 960));
}

@media only screen and (min-width: 800px) {
  :root {
    --large-font: calc(30px + 6 * ((100vw - 320px) / 680));
    --medium-font: 20px;
  }
}

@media only screen and (max-width: 800px) {
  :root {
    --medium-font: calc(15px + 12 * ((100vw - 320px) / 960));
    --fifth-color: #242c30;
  }
}

.sign-in-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-width: 200px;
  width: 30%;
  min-height: 500px;
  height: 50%;

  /* background-color: var(--fourth-color); */
}

.popux-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  text-align: center;
  min-width: 200px;
  width: 30%;
  min-height: 500px;
  height: 50%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-family: "Pacifico", cursive;
  color: var(--main-color);
  font-size: var(--large-font);
  flex-wrap: nowrap;
  white-space: nowrap;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
}

.top-bottom-group {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
}

.gradient-text {
  /* background: -webkit-linear-gradient(rgb(101, 43, 167), rgb(31, 45, 173)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animated-gradient-bg {
  background: linear-gradient(270deg, var(--fourth-color), var(--fifth-color));
  background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.linear-wipe {
  text-align: center;

  background: linear-gradient(
    to right,
    var(--fifth-color) 20%,
    var(--fifth-color) 50%,
    var(--second-color) 60%,
    var(--fifth-color) 70%,
    var(--fifth-color) 100%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 12s linear infinite;
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.title-size {
  font-size: calc(30px + 6 * ((100vw - 320px) / 680)) !important;
}

.navbar-title,
.gradient-text {
  font-size: calc(12px + 6 * ((100vw - 320px) / 680));
  padding: 0 15px 0 15px;
  font-family: "Pacifico", cursive;
}

body {
  background-color: #282c34;
}

.App {
  /* text-align: center;
  max-width: 728px;
  margin: 0 auto; */
}

.App header {
  background-color: var(--fifth-color);
  min-height: 50px;
  color: var(--third-color);
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.4);
}

form {
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.4);
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  background-color: var(--fifth-color);
}

.app-container {
  display: flex;
  justify-items: center;
  align-self: center;

  width: 100%;
  height: 100%;
}

.typing-bar-container form {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 10vh;
  background-color: rgb(24, 23, 23);
  width: 100%;
  font-size: 1.5rem;
}

main {
  padding: 65px 10px 10px 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: var(--fifth-color);
}

main::-webkit-scrollbar-thumb {
  background: var(--fifth-color);
}

input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: var(--fifth-color);
  font-family: "Roboto Slab", serif;
  color: white;
  outline: none;
  border: none;
  padding: 5px 5px 5px 30px;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-button {
  transition: all ease-in-out 0.2s;
}

.custom-button:hover {
  opacity: 0.5;
}

.send-message-button {
  font-family: "Roboto Slab", serif;
}

.sign-in,
.sign-out {
  font-family: "Roboto Slab", serif;
  padding: 25px;
  font-weight: 600;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);
}

.sign-in:hover,
.sign-out:hover {
}

.sign-in {
  background-color: var(--third-color);
  color: var(--main-color);
  max-width: 400px;
  margin: 0 auto;
}

.sign-out {
  background-color: var(--main-color);
}

ul,
li {
  text-align: left;
  list-style: none;
}

.time-container {
  color: rgba(255, 255, 255, 0.247);
  width: 100%;
  text-align: center;
  /* padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.178); */
}

.time-expire-soon-container {
  /* background-color: rgba(255, 0, 0, 0.2) !important; */
  color: rgba(255, 0, 0, 0.4);
  padding: 10px;
  border-radius: 10px;
  animation-name: slow-flash;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

@keyframes slow-flash {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

@media only screen and (min-width: 600px) {
  p {
    max-width: 50%;
  }
}

p {
  margin-bottom: 12px;
  margin-top: 0;
  word-break: break-word;
  line-height: 24px;
  padding: 20px 20px;
  border-radius: 13px;
  position: relative;
  color: white;
}

.message {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

p {
  font-size: var(--medium-font);
  line-height: 30px;
}

.sent p {
  color: white;
  background: var(--second-color);
  align-self: flex-end;
}
.received p {
  background: var(--third-color);
  color: black;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}