.NoAccess {
    height: 100%;
    width: 100%;
}

.no-access-container{
    display: flex;
    position: fixed;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    max-width: 600px;
    width: 50%;
    min-height: 600px;
    height: 50%;
    
    justify-items: center;
    align-items: center;
    border-radius: 12px;
    background-color: rgb(3, 2, 27);
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.2);
}

.no-access-title{
    font-weight: 600;
    font-size: 2rem;
    color: white;
    text-align: center;
    width: 100%;
}